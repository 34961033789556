import TopNavigation, {
  TopNavigationProps,
} from '@amzn/awsui-components-react/polaris/top-navigation';
import { DEFAULT_LC_BUILDER, Images, Languages, URLS, UserActionNames } from 'src/constants';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import { IsItDown } from 'src/components/common/IsItDown';
import { ParcelPlaybackBaseState } from 'src/stores/app';
import { Spinner } from '@amzn/awsui-components-react';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { debug } from 'src/utils/utils';
import i18n from 'src/i18n';
import { useState } from 'react';
import { useHookstate } from '@hookstate/core';

interface ITopNav {
  setDarkModeCallback: Function;
  setShowSelectSite: Function;
  siteCode: string | undefined;
  username: string;
}

export const TopNav = (props: ITopNav) => {
  debug(`TopNav() props is ${JSON.stringify(props)}`);

  const parcelPlaybackBaseState = useHookstate(ParcelPlaybackBaseState);

  const [darkMode, setDarkMode] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Navigation');
  const { localizationContext, setLocalizationContext } = useLocalizationContext();

  const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'More',
  };

  let stage = 'gamma';
  debug(`TopNav() stage is ${stage}`);

  const [title, setTitle] = useState<string>(() => {
    let title = 'Parcel Playback';
    if (stage.toLowerCase() !== 'prod') title += ` - ${stage.toUpperCase()}`;
    return title;
  });

  const handleLanguageChange = (e: any) => {
    const language = e.detail.id;
    i18n.changeLanguage(language);
    setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(language).build());
  };

  if (isBundleLoading) return <Spinner />;

  let topMenuItems: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      iconName: 'edit',
      text: props.siteCode
        ? `${props.siteCode} - ${bundle.getMessage('change-site')}`
        : `${bundle.getMessage('change-site')}`,
      onClick: () => {
        props.setShowSelectSite(true);
      },
    },
    {
      type: 'button',
      iconName: darkMode ? 'caret-up-filled' : 'caret-down-filled',
      text: darkMode ? bundle.getMessage('light') : bundle.getMessage('dark'),
      onClick: () => {
        createUserAction({
          actionName: UserActionNames.UIModeChange,
          username: parcelPlaybackBaseState.username.value,
          parameters: JSON.stringify({
            mode: darkMode ? bundle.getMessage('light') : bundle.getMessage('dark'),
          }),
        });
        debug(`TopNav() darkMode onClick()`);
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', (!darkMode).toString());
        props.setDarkModeCallback(!darkMode);
      },
    },
    {
      type: 'button',
      text: bundle.getMessage('guardian-hub'),
      ariaLabel: 'Guardian Hub',
      href: URLS.GuardianHub,
      external: true,
      badge: false,
      disableUtilityCollapse: true,
    },
    {
      type: 'menu-dropdown',
      text: bundle.getMessage('language'),
      items: Languages,
      onItemClick: handleLanguageChange,
    },
    {
      type: 'menu-dropdown',
      text: bundle.getMessage('help'),
      items: [
        {
          external: true,
          externalIconAriaLabel: ' (opens in new tab)',
          href: URLS.RiverGuide,
          id: 'guide',
          text: bundle.getMessage('contact-us'),
        },
        {
          external: true,
          externalIconAriaLabel: ' (opens in new tab)',
          text: bundle.getMessage('user-guide'),
          id: 'guide',
          href: URLS.UserGuide,
        },
      ],
    },
    {
      type: 'button',
      text: `${props.username}@`,
      iconName: 'user-profile',
      disableTextCollapse: true,
    },
  ];

  return (
    <>
      <IsItDown />
      <TopNavigation
        i18nStrings={i18nStrings}
        identity={{
          href: '#',
          title: title,
          logo: {
            src: Images.home,
            alt: '',
          },
        }}
        data-testid='TopNav'
        utilities={topMenuItems}
      />
    </>
  );
};
