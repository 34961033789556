import {
  URLS,
  UserActionNames,
} from 'src/constants';
import {
  useEffect,
  useState,
} from 'react';
import { Auth } from 'aws-amplify';
import AuthorizeUser from './AuthorizeUser';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { ParcelPlaybackBaseState } from 'src/stores/app';
import { Spinner } from '@amzn/awsui-components-react';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { debug } from 'src/utils/utils';
import { jwtDecode } from 'jwt-decode';
import { useHookstate } from '@hookstate/core';

export default function AuthenticateUser() {
  debug('AuthenticateUser()');

  const [authFailed, setAuthFailed] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>('');
  const [decodedTokenValue, setDecodedTokenValue] = useState<any>();

  document.title = 'Parcel Playback Configuration';

  const parcelPlaybackBaseState = useHookstate(ParcelPlaybackBaseState);

  let componentToRender;

  useEffect(() => {
    debug('AuthenticateUser() useEffect([])');
    setAuthFailed(false);
    Auth.currentAuthenticatedUser()
      .then(
        async (cognitoUserData: {
          username: string;
          signInUserSession: { idToken: { jwtToken: any } };
        }) => {
          debug(
            `AuthenticateUser() useEffect([]) currentAuthenticateUser() cognitoUserData is ${JSON.stringify(cognitoUserData)}`,
          );
          const username = cognitoUserData.username.split('_')[1];
          parcelPlaybackBaseState.username.set(username);
          debug(
            `AuthenticateUser() useEffect([]) currentAuthenticatedUser() username is ${username}`,
          );
          try {
            const jwtToken = cognitoUserData.signInUserSession.idToken.jwtToken;
            const decodedTokenValue = jwtDecode(jwtToken);
            debug(
              `AuthenticateUser() useEffect([]) currentAuthenticateUser() decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`,
            );
            setDecodedTokenValue(decodedTokenValue);
          } catch (error) {
            debug(
              `AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error} JSON.stringify: ${JSON.stringify(error)}`,
            );
          }
        },
      )
      .catch((error) => {
        debug(
          `AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${JSON.stringify(error)}`,
        );
        setAuthError(error);
        Auth.federatedSignIn({
          customProvider: 'AmazonFederate',
          customState: window.location.href,
        }).catch((error) => {
          debug(
            `AuthenticateUser() useEffect([]) federatedSignIn() error is ${JSON.stringify(error)}`,
          );
          setAuthFailed(true);
          setAuthError(error);
        });
      });
  }, []);

  if (parcelPlaybackBaseState.username.value && decodedTokenValue) {
    debug(
      `AuthenticateUser() authentication complete, parcelPlaybackBaseState.username.value is ${parcelPlaybackBaseState.username.value} decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`,
    );
    createUserAction({
      actionName: UserActionNames.AuthenticateUser,
      username: parcelPlaybackBaseState.username.value,
    });
    componentToRender = (
      <div>
        <AuthorizeUser parcelPlaybackState={parcelPlaybackBaseState} />
      </div>
    );
  } else if (authFailed) {
    debug(`AuthenticateUser() authFailed`);
    createUserAction({
      actionName: UserActionNames.AuthenticateUserError,
      username: parcelPlaybackBaseState.username.value,
      parameters: JSON.stringify({
        authError: authError,
      }),
    });
    componentToRender = (
      <Box margin={{ top: 's', left: 's' }} color='inherit' display='block' fontSize='heading-l'>
        Failed to authenticate user, please try again or contact
        <Link external href={URLS.Contact}>
          {' '}
          SIDE Support
        </Link>
      </Box>
    );
  } else {
    debug(`AuthenticateUser() authenticating`);
    componentToRender = (
      <Box margin={{ top: 's', left: 's' }} color='inherit' display='block' fontSize='heading-l'>
        Authenticating User...
        <Spinner />
      </Box>
    );
  }

  return componentToRender;
}
