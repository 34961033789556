import React, { useState, useEffect } from 'react';
import Link from '@amzn/awsui-components-react/polaris/link';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Linkify from 'react-linkify';
import axios from 'axios';

interface IsItDownResponse {
  name: string;
  message: string;
  level: number;
  tickets: string;
}

enum AlertTypes {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

const IS_IT_DOWN_BASE_URL = 'https://is-it-down.amazon.com/parcelplayback';

// This part of the code refer to: https://sage.amazon.com/posts/548517
export function IsItDown() {
  const [isItDownResponse, setIsItDownResponse] = useState<IsItDownResponse | null>(null);
  let alertType: AlertTypes;

  useEffect(() => {
    const checkIsItDown = () => {
      const stage = 'gamma';
      let isItDownApi = `${IS_IT_DOWN_BASE_URL}/components/${stage.toLowerCase()}.js`;
      axios
        .get<IsItDownResponse>(isItDownApi, { withCredentials: true })
        .then((response) => {
          setIsItDownResponse(response.data);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };
    checkIsItDown();
    setInterval(() => {
      checkIsItDown();
    }, 300000);
  }, []);

  switch (isItDownResponse?.level) {
    case 2: {
      alertType = AlertTypes.warning;
      break;
    }
    case 3: {
      alertType = AlertTypes.error;
      break;
    }
    default:
      alertType = AlertTypes.info;
  }

  const removeAnchorTagFromString = (input = '') => {
    const startAnchorTag = /(<a\b[^>]*>)/g;
    const endAnchorTag = /(<\/a>)/g;

    let parsedInput = input.replace(startAnchorTag, '');

    parsedInput = parsedInput.replace(endAnchorTag, '');
    return parsedInput;
  };

  /* is-it-down websites sends an anchor tag text whenever there is a link in the banner content
  Eg, "For more information check <a href="some_link">some_link</a>", the Alert component just displays
  the text with the html tag init. In order to render text as a link we are using removeAnchorTagFromString
  fn and then create a link using Linkify*/
  return (
    isItDownResponse && (
      <div aria-live='assertive' role='alert'>
        {isItDownResponse.level !== 0 && (
          <Alert type={alertType} className='awsui'>
            <Linkify>{removeAnchorTagFromString(isItDownResponse.message)}</Linkify>.
            <Link
              href={`${IS_IT_DOWN_BASE_URL}/components/${isItDownResponse.name.toLowerCase()}`}
              target='parcelplaybackIsItDown'
            >
              {' '}
              Is Parcel Playback Down?
            </Link>
          </Alert>
        )}
      </div>
    )
  );
}
