import {
  Alert,
  Box,
  Button,
  Flashbar,
  Modal,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import {
  Camera,
  DeleteCameraLinkInput,
} from 'src/API';
import {
  CameraLinkWithCameraNameAndDesc,
  ColumnDefinitions,
  ColumnDisplay,
  TableEmptyState,
  TableNoMatchState,
} from './table-config';
import {
  QueryKeys,
  StepOptions,
} from 'src/constants';
import {
  useEffect,
  useState,
} from 'react';
import {
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { fetchCameraImage } from 'src/utils/FetchCameraImage';
import Header from '@amzn/awsui-components-react/polaris/header';
import { State } from '@hookstate/core';
import { CameraLinkForm } from './CameraLinkForm';
import { ParcelPlaybackBaseStateInterface } from 'src/stores/app';
import { deleteCameraLink } from 'src/utils/utils';
import { fetchPageSize } from 'src/utils/UserPreferences';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

export interface CameraWithNameAndDesc extends Camera {
  name: string;
  description: string;
}
export interface CameraLinksTablePanelPropsInterface {
  parcelPlaybackState: State<ParcelPlaybackBaseStateInterface>;
}

export default function CameraLinksTablePanel(props: CameraLinksTablePanelPropsInterface) {

  const cameraLinksQuery = useQuery({ queryKey: [QueryKeys.cameraLinks], enabled: false });
  const camerasQuery = useQuery({ queryKey: [QueryKeys.cameras], enabled: false });

  const [allItems, setAllItems] = useState<CameraLinkWithCameraNameAndDesc[]>([]);
  const [createDisabled, setCreateDisabled] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [cameraLinkSaved, setCameraLinkSaved] = useState<boolean>(false);
  const [editCameraLink, setEditCameraLink] = useState<CameraLinkWithCameraNameAndDesc>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [hideCameraLinksTable, setHideCameraLinksTable] = useState<boolean>(false);
  const [selectedCameraLinks, setSelectedCameraLinks] = useState<CameraLinkWithCameraNameAndDesc[]>([]);
  const [showCameraLinkForm, setShowCameraLinkForm] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.CameraLinks.TablePanel');

  const [pageSize, handlePageSizeChange] = fetchPageSize('camera_links', 15);

  const { timeStamp, blobUrl, cameraImage, loading, fetchImage } = fetchCameraImage();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems.sort((a,b) => 
      StepOptions.find(s => s.value === a.stepName)?.sequence! < StepOptions.find(s => s.value === b.stepName)?.sequence!
      ? -1
      : 1),
    {
      filtering: {
        empty: (
          <TableEmptyState title={isBundleLoading ? 'Not Found' : bundle.getMessage('not-found')} />
        ),
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
        filteringFunction: (item, filteringText) =>
          item.stepName.toLowerCase().includes(filteringText.toLowerCase())
          || item.camerasWithNameAndDesc?.find(c => c.description.toLowerCase().includes(filteringText.toLowerCase())) !== undefined
          || item.camerasWithNameAndDesc?.find(c => c.name.toLowerCase().includes(filteringText.toLowerCase())) !== undefined
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'stepName' },
    });

  const deleteCameraLinkMutation = useMutation({
    mutationFn: deleteCameraLink,
    onError: (error) => {
      console.error('deleteCameraLinkMutation() error', error);
      setErrorMessage(error.message);
    },
    onSuccess: () => {
      cameraLinksQuery.refetch();
    },
  });

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const cancelCreate = () => {
    setEditCameraLink(undefined);
    setShowCameraLinkForm(false);
    setHideCameraLinksTable(false);
  };

  const createBtnClickHandler = () => {
    setEditCameraLink(undefined);
    setHideCameraLinksTable(true);
    setShowCameraLinkForm(true);
    setCameraLinkSaved(false);
  };

  const createdCameraLinks = () => {
    if (!allItems) return;
    setHideCameraLinksTable(false);
    setShowCameraLinkForm(false);
    setCameraLinkSaved(true);
  };

  const editBtnClickHandler = () => {
    setCameraLinkSaved(false);
    setHideCameraLinksTable(true);
    setEditCameraLink(selectedCameraLinks[0]);
    setShowCameraLinkForm(true);
  };

  const deleteBtnClickHandler = () => {
    if (selectedCameraLinks.length > 0) setDeleteVisible(true);
  };

  const deleteNoBtnClickHandler = () => {
    setDeleteVisible(false);
  };

  const deleteYesBtnClickHandler = async () => {
    setDeleting(true);
    for (let selectedItem of selectedCameraLinks) {
      const deleteCameraLinkInput: DeleteCameraLinkInput = {
        partitionKey: `${selectedItem.siteCode}#${selectedItem.stepName}`,
      };
      deleteCameraLinkMutation.mutate(deleteCameraLinkInput);
    }
    setSelectedCameraLinks([]);
    setDeleteVisible(false);
    setCameraLinkSaved(true);
    setDeleting(false);
  };

  const refreshBtnClickHandler = async () => {
    setCameraLinkSaved(false);
    setSelectedCameraLinks([]);
    if ((camerasQuery.data as CameraLinkWithCameraNameAndDesc[]).length === 0) await camerasQuery.refetch();
    cameraLinksQuery.refetch();
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const selectCameraLink = (cameraLink: CameraLinkWithCameraNameAndDesc) => {
    if (cameraLink) {
      setSelectedCameraLinks([cameraLink]);
      setEditCameraLink(cameraLink);
      setHideCameraLinksTable(true);
      setShowCameraLinkForm(true);
    }
  };

  const FlashMessageSaved = () => (
    <Flashbar
      items={[
        {
          content: bundle.getMessage('saved'),
          dismissible: true,
          onDismiss: () => setCameraLinkSaved(false),
          type: 'success',
        },
      ]}
    />
  );

  const FlashMessageError = () => (
    <Flashbar
      items={[
        {
          content: errorMessage,
          dismissible: true,
          onDismiss: () => setErrorMessage(undefined),
          type: 'error',
        },
      ]}
    />
  );

  useEffect(() => {
    setAllItems(cameraLinksQuery.data as CameraLinkWithCameraNameAndDesc[] ?? []);
    setSelectedCameraLinks([]);
  }, [cameraLinksQuery.data]);

  useEffect(() => {
    setSelectedCameraLinks([]);
  }, [props.parcelPlaybackState]);

  useEffect(() => {
    let cameraLinksCount = 0;
    if (cameraLinksQuery?.data) {
      cameraLinksCount = (cameraLinksQuery?.data as CameraLinkWithCameraNameAndDesc[]).length ?? 0;
    }
    setCreateDisabled(cameraLinksCount >= StepOptions.length);
  }, [cameraLinksQuery?.data]);

  if (isBundleLoading) return <Spinner />;

  return (
    <>
      {cameraLinkSaved && <FlashMessageSaved />}
      {errorMessage && <FlashMessageError />}
      <div id='cameraLinkTableDiv' hidden={hideCameraLinksTable}>
        <Table
          {...collectionProps}
          columnDefinitions={ColumnDefinitions({
            timeStamp,
            blobUrl,
            cameraImage,
            loading,
            fetchImage,
            selectCameraLink,
          })}
          columnDisplay={ColumnDisplay}
          empty={cameraLinksQuery.status == 'error'
            ?
              <Alert type='error'>
                Error: {cameraLinksQuery.error.message}
              </Alert>
            : bundle.getMessage('no-camera-links-found')
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter Step Names'
              filteringPlaceholder={bundle.getMessage('find-step')}
              countText={getFilterCounterText(
                filteredItemsCount === undefined ? 0 : filteredItemsCount,
              )}
            />
          }
          header={
            <Header
              counter={`(${itemsCount().toString()})`}
              actions={
                <>
                  <SpaceBetween size='xs' direction='horizontal'>
                    <Button
                      iconName='refresh'
                      loading={
                        cameraLinksQuery.fetchStatus === 'fetching'
                        || cameraLinksQuery.isFetching
                        || cameraLinksQuery.isLoading
                        || camerasQuery.fetchStatus === 'fetching'
                        || camerasQuery.isFetching
                        || camerasQuery.isLoading
                      }
                      onClick={refreshBtnClickHandler}
                    />
                    <Button
                      disabled={selectedCameraLinks.length !== 1}
                      onClick={editBtnClickHandler}
                    >
                      {bundle.getMessage('edit')}
                    </Button>
                    <Button
                      disabled={selectedCameraLinks.length == 0}
                      onClick={deleteBtnClickHandler}
                    >
                      {bundle.getMessage('delete')}
                    </Button>
                    <Button
                      onClick={createBtnClickHandler}
                      variant='primary'
                      disabled={createDisabled}
                      disabledReason='All steps defined'
                    >
                      {bundle.getMessage('create')}
                    </Button>
                  </SpaceBetween>
                </>
              }
            >
              {bundle.getMessage('camera-links')}
            </Header>
          }
          items={items}
          loading={
            cameraLinksQuery.fetchStatus === 'fetching'
            || cameraLinksQuery.isFetching
            || cameraLinksQuery.isLoading
            || camerasQuery.fetchStatus === 'fetching'
            || camerasQuery.isFetching
            || camerasQuery.isLoading
          }
          loadingText={`${bundle.getMessage('loading')}`}
          onSelectionChange={({ detail }) => setSelectedCameraLinks(detail.selectedItems)}
          resizableColumns={true}
          selectedItems={selectedCameraLinks}
          selectionType='multi'
          stickyHeader={false}
        />
      </div>
      <Modal
        onDismiss={() => setDeleteVisible(false)}
        visible={deleteVisible}
        closeAriaLabel='Close'
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={deleteNoBtnClickHandler}>
                {bundle.getMessage('no')}
              </Button>
              <Button variant='primary' onClick={() => deleteYesBtnClickHandler()} loading={deleting}>
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={bundle.getMessage('delete')}
      >
        {bundle.getMessage('confirm-delete')}
      </Modal>
      {showCameraLinkForm && (
        <CameraLinkForm
          cameraLink={editCameraLink}
          cancelCreateCallback={cancelCreate}
          saveCallback={createdCameraLinks}
        />
      )}
    </>
  );
}
