import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import { IconContext } from 'react-icons';
import { IoVideocam } from 'react-icons/io5';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { Link, Spinner } from '@amzn/awsui-components-react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useBundle } from '@amzn/react-arb-tools';
import { CameraLink, Step } from 'src/API';
import { CameraWithNameAndDesc } from './CameraLinksTablePanel';

export interface CameraLinkWithCameraNameAndDesc extends CameraLink {
  cameraLink: CameraLink;
  stepName: Step;
  camerasWithNameAndDesc: CameraWithNameAndDesc[];
  created: string;
  updated: string;
}

interface ColumnDefinitionsProps {
  cameraImage: string | undefined;
  loading: boolean;
  blobUrl: string | undefined;
  timeStamp: string | undefined;
  fetchImage: (cameraId: string) => void;
  selectCameraLink: (cameraLink: CameraLinkWithCameraNameAndDesc) => void;
}

export const ColumnDefinitions: (props: ColumnDefinitionsProps) => TableProps.ColumnDefinition<CameraLinkWithCameraNameAndDesc>[] = ({
  timeStamp,
  blobUrl,
  cameraImage,
  loading,
  fetchImage,
  selectCameraLink,
}) => [
  {
    id: 'stepName',
    header: <TranslateHeader value='step' />,
    cell: (item) =>
      <Link
        onClick={async () => selectCameraLink(item)}
      >
      {item.stepName}
    </Link>,
    sortingField: 'stepName',
  },
  {
    id: 'cameraName',
    header: <TranslateHeader value={'cameras'} />,
    cell: (item) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        <ul style={{ listStyleType: 'none',  margin: 0, padding: 0 }}>
        {item.camerasWithNameAndDesc?.map((camera, index) =>
          <li key={index.toString()}>
            {camera?.name}
            <Popover
              content={
                <div>
                  {loading
                  ?
                    <Spinner />
                  :
                    cameraImage
                    &&
                    <>
                      <img
                        src={cameraImage}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                      {blobUrl
                      &&
                      <>
                        <a href={blobUrl} target='_blank' rel='noopener noreferrer'>
                          <TranslateHeader value={'image-full-size'} />
                        </a>
                        <div>{timeStamp}</div>
                      </>}
                    </>
                  }
                </div>
              }
              dismissButton={false}
              triggerType='custom'
              size='large'
            >
              <IconContext.Provider
                value={{
                  style: {
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                    marginLeft: '8px',
                    width: '16px',
                    height: '16px',
                  },
                }}
              >
                <IoVideocam key={index.toString()} onClick={() => fetchImage(camera?.systemIdentifier ?? '')} />
              </IconContext.Provider>
            </Popover>
          </li>)}
        </ul>
        </div>
    ),
    sortingField: 'cameraName',
  },
  {
    id: 'created',
    header: <TranslateHeader value={'created'} />,
    cell: (item) => item.created,
  },
  {
    id: 'updated',
    header: <TranslateHeader value={'updated'} />,
    cell: (item) => item.updated,
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.CameraLinks.tableConfig');
  if (isBundleLoading) return <Spinner />;
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string;
}

export function TableEmptyState({ title }: EmptyStateProps) {
  return (
    <Box textAlign='center' color='inherit'>
      <Box variant='strong' textAlign='center' color='inherit'>
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined }) => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <div>
        <b>No matches</b>
        <Box variant='p' color='inherit'>
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100,
};

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

export const ColumnDisplay = [
  { id: 'stepName', visible: true },
  { id: 'cameraName', visible: true },
];
