import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import { Component } from 'react';
import { ParcelPlaybackConfigNavigation } from 'src/components/navigation';
import { ParcelPlaybackBaseStateInterface } from 'src/stores/app';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import { State } from '@hookstate/core';
import { appLayoutLabels } from 'src/common/labels';
import CameraLinksTablePanel from './CameraLinksTablePanel';

export class CameraLinks extends Component<{parcelPlaybackState: State<ParcelPlaybackBaseStateInterface>}> {
  state = {
    navigationOpen: true,
  };

  onNavigationChange(event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) {
    this.setState({
      navigationOpen: event.detail.open,
    });
  }

  render() {
    return (
      <div id='CameraLinks'>
        <AppLayout
          ariaLabels={appLayoutLabels}
          content={
            <CameraLinksTablePanel
              parcelPlaybackState={this.props.parcelPlaybackState}
            />}
          contentType='table'
          disableBodyScroll
          disableContentPaddings
          headerSelector='#topNavigation'
          navigation={
            <ParcelPlaybackConfigNavigation parcelPlaybackState={this.props.parcelPlaybackState} />
          }
          navigationOpen={this.state.navigationOpen}
          onNavigationChange={(event) => this.onNavigationChange(event)}
          stickyNotifications
          toolsHide
        />
      </div>
    );
  }
}

export default CameraLinks;
