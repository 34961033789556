import { Amplify, Auth } from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import AuthenticateUser from './components/AuthenticateUser';
import './i18n';
import { DEFAULT_LC_BUILDER, DEFAULT_LOCALE } from './constants';
import { ErrorBoundary } from 'react-error-boundary';
import { Spinner } from '@amzn/awsui-components-react';
import { MbmProvider } from '@amzn/react-arb-tools';
import { StrictMode, Suspense } from 'react';
import FallbackError from './components/common/FallbackError';

const arbManifest = require('../build/i18n/arbManifest');

if ('gamma'.toLowerCase() !== 'prod') {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

const logger = new Amplify.Logger('test');
logger.log(`index.tsx`);
console.debug('Stage is ' + 'gamma');
console.debug('APPSYNC_API_DOMAIN is ' + 'api.gamma.parcel-playback.gso.amazon.dev');
console.debug('AWS_REGION is ' + 'us-east-1');
console.debug('IDENTITY_POOL_DOMAIN is ' + 'gso-parcel-playback-gamma.auth.us-east-1.amazoncognito.com');
console.debug('IDENTITY_POOL_ID is ' + 'us-east-1:a7e9cc71-6196-4c33-aaa1-195cba077bc9');
console.debug('USER_POOL_ID is ' + 'us-east-1_ZpRjGughh');

const callbackUrl = window.location.origin;
const config = {
  oauth: {
    domain: 'gso-parcel-playback-gamma.auth.us-east-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code',
  },
  // Cognito
  aws_cognito_identity_pool_id: 'us-east-1:a7e9cc71-6196-4c33-aaa1-195cba077bc9',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_ZpRjGughh',
  aws_user_pools_web_client_id: '4p5sfh7f5h6kch2s2acrdtccjt',
  // AppSync
  aws_appsync_graphqlEndpoint: 'https://api.gamma.parcel-playback.gso.amazon.dev/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  API: {
    graphql_endpoint: 'https://api.gamma.parcel-playback.gso.amazon.dev/graphql',
    graphql_headers: async () => ({
      Authorization: (await Auth.currentAuthenticatedUser())
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken(),
    }),
  },
};
Amplify.configure(config);

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE)
    .withDefaultLocale(DEFAULT_LOCALE)
    .build(),
  urlPrefix: '/i18n',
};

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Suspense fallback={<Spinner size='large' />}>
      <ErrorBoundary FallbackComponent={FallbackError}>
        <MbmProvider {...mbmOptions}>
          <AuthenticateUser />
        </MbmProvider>
      </ErrorBoundary>
    </Suspense>
  </StrictMode>,
);
