import * as APIt from 'src/API';
import * as uuid from 'uuid';
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { UserActionNames } from 'src/constants';
import { createUserAction as createUserActionMutation } from 'src/graphql/mutations';
import { debug } from './utils';

export const ttl = (years: number): number => {
  const ttlDate = new Date();
  ttlDate.setFullYear(ttlDate.getFullYear() + years);
  const ttlEpochInt: number = Math.round(ttlDate.getTime() / 1000);
  return ttlEpochInt;
};

export interface CreateUserActionInterface {
  username: string | undefined;
  actionName: UserActionNames;
  parameters?: string;
}
export const createUserAction = async (
  userAction: CreateUserActionInterface,
): Promise<APIt.UserAction | undefined | null> => {
  debug(`createUserAction(): userAction is ${JSON.stringify(userAction)}`);

  try {
    const createUserActionMutationReponse = (await API.graphql(
      graphqlOperation(createUserActionMutation, {
        input: {
          actionId: uuid.v4(),
          actionName: userAction.actionName,
          parameters: userAction.parameters,
          timestamp: new Date().getTime().toString(),
          ttl: ttl(1),
          username: userAction.username,
        },
      }),
    )) as GraphQLResult<APIt.CreateUserActionMutation>;
    debug(
      `createUserAction(): createUserActionMutationReponse is ${JSON.stringify(createUserActionMutationReponse)}`,
    );
    return createUserActionMutationReponse?.data?.createUserAction as APIt.UserAction;
  } catch (error) {
    console.error(`createUserAction(): error is ${JSON.stringify(error)}`);
    throw error;
  }
};
