import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import {
  Button,
  FormField,
  Modal,
  SpaceBetween,
  Spinner,
  Container,
  Select,
  Input,
  Tabs,
  Cards,
  Link,
  Flashbar,
  Form,
  DatePicker,
  SelectProps,
  InputProps,
  Multiselect,
} from '@amzn/awsui-components-react';
import {
  Camera,
  CameraLink,
  Step,
  VMS,
} from 'src/API';
import {
  ParcelPlaybackBaseState,
  ParcelPlaybackSearchState,
} from 'src/stores/app';
import {
  QueryKeys,
  StepOptions,
  UserActionNames,
} from 'src/constants';
import {
  debug,
  getParcelTracking,
  validDate,
} from 'src/utils/utils';
import {
  memo,
  useRef,
} from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import { CameraWithNameAndDesc } from '../CameraLinks/CameraLinksTablePanel';
import ReactPlayer from 'react-player';
import { addSeconds } from 'date-fns/addSeconds';
import { addDays } from 'date-fns';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { useHookstate } from '@hookstate/core';
import { useQuery } from '@tanstack/react-query';

class NoParcelDataFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NoParcelDataFoundError';
  }
}

export interface CardItemsInterface {
  stepName: string;
  items: {
    name: string;
    url: string;
    cameraId: string;
    startTime: string;
    endTime: string;
  }[];
}

export interface ModalComponentInterface {
  url: string;
  isModalLoading: boolean;
}

export interface Device {
  cameraName: string;
  cameraSystemId: string;
}
export interface SelectOptionInterface {
  label: string;
  value: string;
}

interface CardComponentProps {
  stepName: string;
  cardItems: CardItemsInterface[];
  loading: boolean;
  openModal: (cameraId: string, prevButton: boolean, nextButton: boolean, startTime: string, endTime: string) => void;
}

export interface CancelCreateSystemInterface { (): void; }

export default function ParcelSearchTablePanel() {

  const cameraLinksQuery = useQuery({ queryKey: [QueryKeys.cameraLinks], enabled: false });
  const camerasQuery = useQuery({ queryKey: [QueryKeys.cameras], enabled: false });

  const parcelPlaybackBaseState = useHookstate(ParcelPlaybackBaseState);
  const parcelPlaybackSearchState = useHookstate(ParcelPlaybackSearchState);

  const [bundle, isBundleLoading] = useBundle('components.ParcelSearch.ParcelSearchTablePanel');

  const callLambda = async (siteCode: string, cameraId: string, startTime: string, endTime: string): Promise<string> => {
    debug(`ParcelSearchTablePanel() callLambda() siteCode is ${siteCode} cameraId is ${cameraId} startTime is ${startTime} endTime is ${endTime}`);

    const params = {
      FunctionName: parcelPlaybackBaseState.value.selectedSite?.vms === VMS.Milestone
        ? 'MileStoneVideoRetrievalLambda'
        : 'BoschVideoRetrievalLambda',
      Payload: JSON.stringify({
        siteCode,
        cameraId,
        startTime,
        endTime,
      }),
    };
    debug(`ParcelSearchTablePanel() callLambda() params is ${JSON.stringify(params)}`);

    try {
      AWS.config.update({
        httpOptions: {
          timeout: 420000
        }
      });
      const getVideoLambda = new AWS.Lambda({ region: 'us-east-1' });
      const response = await getVideoLambda.invoke(params).promise();
      debug(`ParcelSearchTablePanel() callLambda() response is ${JSON.stringify(response)}`);
      const result = JSON.parse(JSON.parse(response.Payload as string).body).signedUrl;
      return result;
    } catch (error) {
      console.error('Error invoking Lambda function:', error);
      throw error;
    }

  };

  const resetSearchParameters = () => {
    parcelPlaybackSearchState.set({
      activeTabId: Step.Induct,
      cameraLoadProgress: {},
      cardItems: [],
      inductDisableTab: true,
      inductLabel: `${Step.Induct} - No Timestamp`,
      invalidSearchDate: false,
      isModalLoading: false,
      isNextButtonDisabled: false,
      isPrevButtonDisabled: false,
      loadingTrackingData: false,
      modalCameraId: '',
      modalCameraName: '',
      modalUrl: '',
      modalVideoStartTime: '',
      modalVideoEndTime: '',
      modalVideoVisible: false,
      noParcelDataFound: false,
      pickDisableTab: true,
      pickLabel: `${Step.Pick} - No Timestamp`,
      postRoll: '15 seconds',
      postRollOptions: undefined,
      postUrl: '',
      preRoll: '15 seconds',
      preRollOptions: undefined,
      preUrl: '',
      searchStartDate: (addDays(new Date(), -7).toISOString().split('T')[0]),
      selectedSteps: StepOptions.map(s => ({
        label: s.label,
        value: s.value,
      })),
      stageDisableTab: true,
      stagingLabel: 'Staging - No Timestamp',
      stepLoadingStatus: {},
      stowDisableTab: true,
      stowLabel: `${Step.Stow} - No Timestamp`,
      trackingId: undefined,
    });
  };

  const fetchParcelData = async (trackingId: string) => {
    debug(`ParcelSearchTablePanel() fetchParcelData() trackingId is ${trackingId}`);

    createUserAction({
      actionName: UserActionNames.ParcelSearch,
      parameters: JSON.stringify({
        trackingId: trackingId,
      }),
      username: parcelPlaybackBaseState.username.value,
    });

    resetFetchParcelData();

    parcelPlaybackSearchState.pickDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Pick) === undefined);
    parcelPlaybackSearchState.inductDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Induct) === undefined);
    parcelPlaybackSearchState.stageDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Staging) === undefined);
    parcelPlaybackSearchState.stowDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Stow) === undefined);
    parcelPlaybackSearchState.loadingTrackingData.set(true);

    const cameraLinks = (cameraLinksQuery.data as CameraLink[]) ?? [];
    debug(`ParcelSearchTablePanel() fetchParcelData() cameraLinks.length is ${cameraLinks.length}`);

    const initializeLoadingStatus = () => {
      const initialStatus: Record<string, boolean> = {};
      cameraLinks.forEach(cl => {
        if (parcelPlaybackSearchState.selectedSteps.value.find(s => s.label === cl.stepName)) {
          initialStatus[cl.stepName] = true;
        }
      });
      parcelPlaybackSearchState.stepLoadingStatus.set(initialStatus);
    };

    const initializeCameraLoadProgress = () => {
      const progress: Record<string, {loaded: number, total: number}> = {};
      cameraLinks.forEach(cl => {
        if (parcelPlaybackSearchState.selectedSteps.value.find(s => s.label === cl.stepName)) {
          progress[cl.stepName] = {loaded:0 , total: cl.cameras?.length ?? 0};
        }
      });
      parcelPlaybackSearchState.cameraLoadProgress.set(progress);
    }

    AWS.config.credentials = await Auth.currentCredentials();
    AWS.config.region = 'us-east-1';

    try {
      const newSearchDate = (new Date(parcelPlaybackSearchState.searchStartDate.value!)
        .setDate((new Date(parcelPlaybackSearchState.searchStartDate.value!)).getDate()));
      const parcelTrackingData = await getParcelTracking(
        parcelPlaybackBaseState.value.selectedSite?.siteCode!,
        parcelPlaybackSearchState.trackingId.value!,
        (new Date(newSearchDate)).toISOString().split('T')[0]);
      if (!parcelTrackingData) throw new NoParcelDataFoundError('No parcel tracking data found');
      if (parcelTrackingData?.pickTime){
        parcelPlaybackSearchState.pickLabel.set(`Pick @ ${parcelTrackingData.pickTime}`);
        parcelPlaybackSearchState.pickDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Pick) === undefined);
      }
      if (parcelTrackingData?.stowTime){
        parcelPlaybackSearchState.stowLabel.set(`Stow @ ${parcelTrackingData.stowTime}`);
        parcelPlaybackSearchState.stowDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Stow) === undefined);
      }
      if (parcelTrackingData?.inductTime){
        parcelPlaybackSearchState.inductLabel.set(`Induct @ ${parcelTrackingData.inductTime}`);
        parcelPlaybackSearchState.inductDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Induct) === undefined);
      }
      if (parcelTrackingData?.stageTime){
        parcelPlaybackSearchState.stagingLabel.set(`Stage @ ${parcelTrackingData.stageTime}`);
        parcelPlaybackSearchState.stageDisableTab.set(parcelPlaybackSearchState.selectedSteps.value.find(s => s.value === Step.Staging) === undefined);
      }
      parcelPlaybackSearchState.loadingTrackingData.set(false);
      
      const getCameraUrls = async (): Promise<void> => {
        initializeLoadingStatus();
        initializeCameraLoadProgress();

        try {
          debug(`ParcelSearchTablePanel() fetchParcelData() getCameraUrls() cameraLinks.length is ${cameraLinks.length}`);
          for (const cameraLink of cameraLinks.filter(cl => parcelPlaybackSearchState.selectedSteps.value.find(s => s.label === cl.stepName))) {
            cameraLink?.cameras?.forEach(async camera => {
              if (!camera || !camera.systemIdentifier) return;
              let time = '';
              switch (cameraLink.stepName) {
                case Step.Induct:
                  time = parcelTrackingData?.inductTime || '';
                  break;
                case Step.Stow:
                  time = parcelTrackingData?.stowTime || '';
                  break;
                case Step.Pick:
                  time = parcelTrackingData?.pickTime || '';
                  break;
                case Step.Staging:
                  time = parcelTrackingData?.stageTime || '';
                  break;
              }

              let preRollSeconds;
              switch (parcelPlaybackSearchState.preRoll.value) {
                case '15 seconds':
                  preRollSeconds = 15;
                  break;
                case '30 seconds':
                  preRollSeconds = 30;
                  break;
                case '45 seconds':
                  preRollSeconds = 45;
                  break;
                case '1 minute':
                  preRollSeconds = 60;
                  break;
                default:
                  preRollSeconds = 15;
              }

              let postRollSeconds;
              switch (parcelPlaybackSearchState.preRoll.value) {
                case '15 seconds':
                  postRollSeconds = 15;
                  break;
                case '30 seconds':
                  postRollSeconds = 30;
                  break;
                case '45 seconds':
                  postRollSeconds = 45;
                  break;
                case '1 minute':
                  postRollSeconds = 60;
                  break;
                default:
                  postRollSeconds = 15;
              }

              const startDate = new Date(time.replace(' ', 'T'));
              startDate.setSeconds(startDate.getSeconds() - preRollSeconds);
              const endDate = new Date(time.replace(' ', 'T'));
              endDate.setSeconds(endDate.getSeconds() + postRollSeconds);
              const startTime = startDate.toISOString();
              const endTime = endDate.toISOString();

              const cameras = camerasQuery.data as Camera[] ?? [];
              const cameraName = cameras.find(c => c.systemIdentifier === camera.systemIdentifier)?.name ?? '';
              const cameraId = camera.systemIdentifier;

              let url: string = '';

              try {
                url = await callLambda(parcelPlaybackBaseState.value.selectedSite!.siteCode, cameraId, startTime, endTime);
                if (!url) url = '';
              } catch (error) {
                console.error(error);
              }

              parcelPlaybackSearchState.cameraLoadProgress.set(currentProgress => {
                const currentStepProgress = currentProgress[cameraLink.stepName] ?? {};
                const currentLoaded = currentStepProgress.loaded ?? 0;
                return {
                  ...currentProgress,
                  [cameraLink.stepName]: {
                    ...currentStepProgress,
                    loaded: url === '' ? currentLoaded : currentLoaded + 1
                  }
                };
              });

              const newCardItem: CardItemsInterface = {
                stepName: cameraLink.stepName,
                items: [
                  {
                    cameraId: cameraId,
                    endTime: endTime,
                    name: cameraName,
                    startTime: startTime,
                    url: url,
                  }
                ]
              };

              parcelPlaybackSearchState.stepLoadingStatus.set(currentStepLoadingStatus => ({
                ...currentStepLoadingStatus,
                [cameraLink.stepName]: false,
              }));
              parcelPlaybackSearchState.cardItems.set(currentCardItems => ([ ...currentCardItems, newCardItem ]));
            });
          }
        } catch (error) {
          console.error('Error fetching camera URLs: ', error);
          parcelPlaybackSearchState.loadingTrackingData.set(false);
          createUserAction({
            actionName: UserActionNames.ParcelSearchError,
            username: parcelPlaybackBaseState.username.value,
            parameters: JSON.stringify({
              error: JSON.stringify(error),
              trackingId: trackingId,
            }),
          });
          throw error;
        } finally {
        }
      };

      try {
        await getCameraUrls();
      } catch (error) {
        console.error(error);
        parcelPlaybackSearchState.loadingTrackingData.set(false);
        createUserAction({
          actionName: UserActionNames.ParcelSearchError,
          username: parcelPlaybackBaseState.username.value,
          parameters: JSON.stringify({
            error: JSON.stringify(error),
            trackingId: trackingId,
          }),
        });
        throw error;
      }
    } catch(error) {
      parcelPlaybackSearchState.loadingTrackingData.set(false);
        createUserAction({
          actionName: UserActionNames.ParcelSearchError,
          username: parcelPlaybackBaseState.username.value,
          parameters: JSON.stringify({
            error: JSON.stringify(error),
            trackingId: trackingId,
          }),
        });
      if (error instanceof NoParcelDataFoundError) {
        parcelPlaybackSearchState.noParcelDataFound.set(true);
      } else {
        console.error('Error:', error);
        throw error;
      }
    }
  };

  const resetFetchParcelData = () => {
    parcelPlaybackSearchState.cameraLoadProgress.set({});
    parcelPlaybackSearchState.cardItems.set([]);
    parcelPlaybackSearchState.inductLabel.set(`${Step.Induct} - No Timestamp`);
    parcelPlaybackSearchState.loadingTrackingData.set(false);
    parcelPlaybackSearchState.noParcelDataFound.set(false);
    parcelPlaybackSearchState.pickLabel.set(`${Step.Pick} - No Timestamp`);
    parcelPlaybackSearchState.stagingLabel.set('Stage - No Timestamp');
    parcelPlaybackSearchState.stowLabel.set(`${Step.Stow} - No Timestamp`);
  }

  const getTabLabelWithProgress = (label: string, stepName: string) => {
    let progress;
    if (parcelPlaybackSearchState.cameraLoadProgress?.value && parcelPlaybackSearchState.cameraLoadProgress.value[stepName]) {
      progress = parcelPlaybackSearchState.cameraLoadProgress?.value[stepName];
    }
    if (progress) {
      return(
        <div>
          <span>{label}</span>
          <br/>
          <span>{progress.loaded}/{progress.total} cameras loaded</span>
        </div>
      );
    } else {
      return `${label}`;
    }
  }

  const openModal = async (cameraId: string, prevButton: boolean, nextButton: boolean, startTime: string, endTime: string) => {
    parcelPlaybackSearchState.isModalLoading.set(true);
    parcelPlaybackSearchState.modalVideoStartTime.set(startTime);
    parcelPlaybackSearchState.modalVideoEndTime.set(endTime);
    parcelPlaybackSearchState.modalVideoVisible.set(true);
    parcelPlaybackSearchState.modalCameraId.set(cameraId);
    const cameras = camerasQuery.data as CameraWithNameAndDesc[];
    const camera = cameras.find(c => c.systemIdentifier == cameraId);
    parcelPlaybackSearchState.modalCameraName.set(camera?.name ?? '');
    let url = '';

    if (prevButton) {
      parcelPlaybackSearchState.isPrevButtonDisabled.set(true);
    }

    if (prevButton && !parcelPlaybackSearchState.preUrl.value) {
      const prevStartTime = addSeconds(new Date(startTime), -30);
      const prevEndTime = addSeconds(new Date(endTime), -30);
      url = await callLambda(parcelPlaybackBaseState.value.selectedSite?.siteCode!, cameraId, prevStartTime.toISOString(), prevEndTime.toISOString());
      parcelPlaybackSearchState.preUrl.set(url);
    } else if (prevButton){
      url = parcelPlaybackSearchState.preUrl.value;
    }

    if (nextButton) {
      parcelPlaybackSearchState.isNextButtonDisabled.set(true);
    }
    
    if (nextButton && !parcelPlaybackSearchState.postUrl.value) {
      const nextStartTime = addSeconds(new Date(startTime), +30);
      const nextEndTime = addSeconds(new Date(endTime), +30);
      url = await callLambda(parcelPlaybackBaseState.value.selectedSite?.siteCode!, cameraId, nextStartTime.toISOString(), nextEndTime.toISOString());
      parcelPlaybackSearchState.postUrl.set(url);
    } else if (nextButton) {
      url = parcelPlaybackSearchState.postUrl.value;
    }
   
    parcelPlaybackSearchState.modalUrl.set(url);
    parcelPlaybackSearchState.isModalLoading.set(false);
  };

  const updateModal = async (cameraId: string, prevButton: boolean, nextButton: boolean, startTime: string, endTime: string) => {
    debug(`ParcelSearchTablePanel() updateModal() cameraId is ${cameraId} prevButton is ${prevButton} nextButton is ${nextButton} startTime is ${startTime} endTime is ${endTime}`);
    parcelPlaybackSearchState.isModalLoading.set(true);
    let updateUrl = '';
    if (prevButton) {
      parcelPlaybackSearchState.isNextButtonDisabled.set(true);
      parcelPlaybackSearchState.isPrevButtonDisabled.set(true);
      if (!parcelPlaybackSearchState.preUrl.value) {
        const prevStartTime = addSeconds(new Date(startTime), -30);
        const prevEndTime = addSeconds(new Date(endTime), -30);
        updateUrl = await callLambda(parcelPlaybackBaseState.value.selectedSite?.siteCode!, cameraId, prevStartTime.toISOString(), prevEndTime.toISOString());
        parcelPlaybackSearchState.preUrl.set(updateUrl);
      } else {
        updateUrl = parcelPlaybackSearchState.preUrl.value;
      }
    }
    if (nextButton) {
      parcelPlaybackSearchState.isNextButtonDisabled.set(true);
      parcelPlaybackSearchState.isPrevButtonDisabled.set(false);
      if (!parcelPlaybackSearchState.postUrl.value) {
        const nextStartTime = addSeconds(new Date(startTime), +30);
        const nextEndTime = addSeconds(new Date(endTime), +30);
        updateUrl = await callLambda(parcelPlaybackBaseState.value.selectedSite?.siteCode!, cameraId, nextStartTime.toISOString(), nextEndTime.toISOString());
        parcelPlaybackSearchState.postUrl.set(updateUrl);
      } else {
        updateUrl = parcelPlaybackSearchState.postUrl.value;
      }
    }
    parcelPlaybackSearchState.modalUrl.set(updateUrl);
    parcelPlaybackSearchState.isModalLoading.set(false);
  };

  const closeModal = () => {
    parcelPlaybackSearchState.isNextButtonDisabled.set(false);
    parcelPlaybackSearchState.isPrevButtonDisabled.set(false);
    parcelPlaybackSearchState.modalVideoVisible.set(false);
  };

  const CardComponent: React.FC<CardComponentProps> = memo(({
    stepName,
    cardItems,
    loading,
    openModal,
  }) => {
    debug(`CardComponent() stepName is ${stepName} cardItems is ${JSON.stringify(cardItems)} loading is ${loading}`);
    const getItemsForStage = (stepName: string, cardItems: CardItemsInterface[]): { name: string; url: string; cameraId: string, startTime: string, endTime: string }[] | undefined => {
      const stage = cardItems.find(item => item.stepName === stepName);
      return stage ? stage.items : undefined;
    };
    const itemsForStage = getItemsForStage(stepName, cardItems);

    return(
      <>
         {loading && (!itemsForStage || itemsForStage.length === 0)
         ?
          <div>
            <Flashbar
              items={[
                {
                  type: 'success',
                  loading: true,
                  content: `${bundle.getMessage('loading-video-message')}...`,
                  id: 'ParcelSearchLoadingVideo',
                },
              ]}
            />
          </div>
        :
          <Cards
            cardDefinition={{
              header: (item) => item.name,
              sections: [
                {
                  id: 'Camera name TBD',
                  content: (item) => {
                    if (item.url === '') {
                      return <div>{`${bundle.getMessage('error-loading-video-message')} `}<a href='https://w.amazon.com/bin/view/AccSys/ParcelPlayback/' target='_blank'>{bundle.getMessage('user-guide')}</a>.</div>;
                    }
                    return <ReactPlayer url={item.url} controls width='100%' />;
                  }
                },
                {
                  id: 'pre-roll/post-roll',
                  content: (item) => (
                    <>
                      <SpaceBetween direction='horizontal' size='s'>
                        {item.url !== ''
                        &&
                        <>
                          <Link onClick={() => openModal(item.cameraId, true, false, item.startTime, item.endTime)}>
                            {bundle.getMessage('pull-previous-30-seconds')}
                          </Link>
                          <Link onClick={() => openModal(item.cameraId, false, true, item.startTime, item.endTime)}>
                            {bundle.getMessage('pull-next-30-seconds')}
                          </Link>
                        </>}
                      </SpaceBetween>
                    </>
                  ),
                },
              ],
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 300, cards: 3 },
            ]}
            items={itemsForStage ?? []}
            loadingText={`${bundle.getMessage('loading-camera-video')}...`}
            loading={loading && !itemsForStage} // Show the loading state only if no items have been retrieved yet
          />
        }
      </>);
  });

  const preRollFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    parcelPlaybackSearchState.preRoll.set(detail.selectedOption.value ?? '');
  };

  const postRollFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    parcelPlaybackSearchState.postRoll.set(detail.selectedOption.value ?? '');
  };

  const trackingIdInputRef = useRef<InputProps.Ref>(null);

  if (isBundleLoading) return <Spinner/>;

  let rollOptions: SelectOptionInterface[] = [
    {label: `15 ${bundle.getMessage('seconds')}`, value: '15 seconds'},
    {label: `30 ${bundle.getMessage('seconds')}`, value: '30 seconds'},
    {label: `45 ${bundle.getMessage('seconds')}`, value: '45 seconds'},
    {label: `1 ${bundle.getMessage('minute')}`, value: '1 minute'}
  ];
  
  return (
    <Container>
      <form onSubmit={async e => {
        e.preventDefault();
        await fetchParcelData(parcelPlaybackSearchState.trackingId.value ?? '');
      }}>
        <Form>
          <Header>Parcel Search</Header>
          <SpaceBetween alignItems='end' direction='horizontal' size='xs'>
            <FormField label={bundle.getMessage('tracking-id')}>
              <Input
                ariaRequired={true}
                autoFocus
                disabled={
                  parcelPlaybackSearchState.loadingTrackingData.value
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
                }
                onChange={({ detail }) => {
                  parcelPlaybackSearchState.trackingId.set(detail.value);
                }}
                placeholder={bundle.getMessage('enter-tracking-id')}
                ref={trackingIdInputRef}
                value={parcelPlaybackSearchState.trackingId.value || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('start-date')}>
              <DatePicker
                ariaRequired={true}
                disabled={
                  parcelPlaybackSearchState.loadingTrackingData.value
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
                }
                invalid={parcelPlaybackSearchState.invalidSearchDate.value}
                isDateEnabled={date => {
                  const [month, day, year] = [
                    (date.getMonth() + 1).toString().padStart(2, '0'),
                    date.getDate().toString().padStart(2, '0'),
                    date.getFullYear(),
                  ];
                  const convertedDate = new Date(`${year}-${month}-${day} 00:00:00`);
                  return validDate(convertedDate, 30);
                }}
                onChange={({ detail }) => {
                  let newSearchDate: string = detail.value;
                  if (newSearchDate === '') newSearchDate = addDays(new Date(), -7).toISOString().split('T')[0];
                  if (!validDate(new Date(newSearchDate!), 30)) {
                    parcelPlaybackSearchState.searchStartDate.set(newSearchDate);
                    parcelPlaybackSearchState.invalidSearchDate.set(true);
                  } else {
                    parcelPlaybackSearchState.searchStartDate.set(newSearchDate);
                    parcelPlaybackSearchState.invalidSearchDate.set(false);
                  }
                }}
                placeholder={new Date().toISOString().split('T')[0]}
                value={parcelPlaybackSearchState.searchStartDate.value ?? ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('preroll-options')}>
              <Select
                disabled={
                  parcelPlaybackSearchState.loadingTrackingData.value
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
                }
                filteringType='auto'
                loadingText={`${bundle.getMessage('loading')}...`}
                onChange={({ detail }) => preRollFieldOnChangeHandler(detail)}
                options={rollOptions}
                selectedAriaLabel='Selected'
                selectedOption={parcelPlaybackSearchState.preRoll.value
                  ? {
                      label: `${parcelPlaybackSearchState.preRoll.value.split(' ')[0]} ${bundle.getMessage(parcelPlaybackSearchState.preRoll.value.split(' ')[1])}`,
                      value: rollOptions.find(r => r.label === parcelPlaybackSearchState.preRoll.value)?.value
                    }
                  : null}
              />
            </FormField>
            <FormField label={bundle.getMessage('postroll-options')}>
              <Select
                disabled={
                  parcelPlaybackSearchState.loadingTrackingData.value
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
                }
                filteringType='auto'
                loadingText={`${bundle.getMessage('loading')}...`}
                onChange={({ detail }) => postRollFieldOnChangeHandler(detail)}
                options={rollOptions}
                selectedAriaLabel='Selected'
                selectedOption={parcelPlaybackSearchState.postRoll.value
                  ? {
                      label: `${parcelPlaybackSearchState.postRoll.value.split(' ')[0]} ${bundle.getMessage(parcelPlaybackSearchState.postRoll.value.split(' ')[1])}`,
                      value: rollOptions.find(r => r.label === parcelPlaybackSearchState.postRoll.value)?.value
                    }
                  : null}
              />
            </FormField>
            <FormField label={bundle.getMessage('steps')}>
              <Multiselect
                disabled={
                  parcelPlaybackSearchState.loadingTrackingData.value
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                  || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
                }
                hideTokens
                options={StepOptions.map(s => (
                  {
                    label: s.label,
                    value: s.value,
                  }
                ))}
                onChange={({ detail }) => {
                  parcelPlaybackSearchState.selectedSteps.set(
                    detail.selectedOptions.map(s => ({ label: s.label ?? '', value: s.value ?? '' })));
                }}
                placeholder={
                  parcelPlaybackSearchState.selectedSteps.value.length === 0
                  ? bundle.getMessage('select-steps')
                  : parcelPlaybackSearchState.selectedSteps.value.map((value) => ` ${value.label}`).toString()
                }
                selectedOptions={parcelPlaybackSearchState.selectedSteps.value}
              />
            </FormField>
            <Button
              disabled={
                parcelPlaybackSearchState.trackingId.value === ''
                || parcelPlaybackSearchState.trackingId.value === undefined
                || parcelPlaybackSearchState.searchStartDate.value === ''
                || parcelPlaybackSearchState.loadingTrackingData.value
                || parcelPlaybackSearchState.invalidSearchDate.value
                || parcelPlaybackSearchState.selectedSteps.value.length === 0
                || camerasQuery.isFetching
                || cameraLinksQuery.isFetching}
              disabledReason={(cameraLinksQuery?.data as CameraLink[]).length === 0 ? 'Ensure camera links are defined' : undefined}
              loading={
                parcelPlaybackSearchState.loadingTrackingData.value
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
              }
              onClick={() => fetchParcelData(parcelPlaybackSearchState.trackingId.value ?? '')}
              variant='primary'
            >
              {bundle.getMessage('search')}
            </Button>
            <Button
              disabled={
                parcelPlaybackSearchState.loadingTrackingData.value
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
              }
              iconName='undo'
              onClick={(event) => {
                event.preventDefault();
                resetSearchParameters();
              }}
              variant='icon'
            />
          </SpaceBetween>
          <br />
          {parcelPlaybackSearchState.noParcelDataFound.value
          &&
          <Flashbar
            items={[
              {
                type: 'warning',
                content: bundle.getMessage('no-parcel-data-found'),
                dismissible: true,
                onDismiss: () => {
                  parcelPlaybackSearchState.noParcelDataFound.set(false);
                },
                id: 'NoParcelDataFoundMessage',
              },
            ]}
          />}
          <Tabs
            activeTabId={parcelPlaybackSearchState.activeTabId.value}
            tabs={[
              {
                label:
                  <>
                    {parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
                    &&
                    <Spinner size='normal'/>}{getTabLabelWithProgress(parcelPlaybackSearchState.inductLabel.value ?? '', Step.Induct)}
                  </>,
                id: 'Induct',
                content:
                  <CardComponent
                    stepName={Step.Induct}
                    cardItems={parcelPlaybackSearchState.cardItems.value as CardItemsInterface[] ?? []}
                    loading={parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]}
                    openModal={openModal}
                  />,
                disabled: parcelPlaybackSearchState.inductDisableTab.value,
              },
              {
                label:
                  <>
                    {parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
                    &&
                    <Spinner size='normal'/>}{getTabLabelWithProgress(parcelPlaybackSearchState.stowLabel.value ?? '', Step.Stow)}
                  </>,
                id: 'Stow',
                content:
                  <CardComponent
                    stepName={Step.Stow}
                    cardItems={parcelPlaybackSearchState.cardItems.value as CardItemsInterface[] ?? []}
                    loading={parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]}
                    openModal={openModal}
                  />,
                disabled: parcelPlaybackSearchState.stowDisableTab.value,
              },
              {
                label:
                  <>
                    {parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
                    &&
                    <Spinner size='normal'/>}{getTabLabelWithProgress(parcelPlaybackSearchState.pickLabel.value ?? '', Step.Pick)}
                  </>,
                id: 'Pick',
                content:
                  <CardComponent
                    stepName={Step.Pick}
                    cardItems={parcelPlaybackSearchState.cardItems.value as CardItemsInterface[] ?? []}
                    loading={parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]}
                    openModal={openModal}
                  />,
                disabled: parcelPlaybackSearchState.pickDisableTab.value,
              },
              {
                label:
                  <>
                    {parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
                    &&
                    <Spinner size='normal'/>}{getTabLabelWithProgress(parcelPlaybackSearchState.stagingLabel.value ?? '', Step.Staging)}
                  </>,
                id: 'Stage',
                content:
                  <CardComponent
                    stepName={Step.Staging}
                    cardItems={parcelPlaybackSearchState.cardItems.value as CardItemsInterface[] ?? []}
                    loading={parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]}
                    openModal={openModal}
                  />,
                disabled: parcelPlaybackSearchState.stageDisableTab.value,
              },
            ]}
            onChange={(event) => parcelPlaybackSearchState.activeTabId.set(event.detail.activeTabId)}
          />
          {parcelPlaybackSearchState.modalVideoVisible.value // for proper unmounting
          &&
          <Modal
            visible={parcelPlaybackSearchState.modalVideoVisible.value}
            onDismiss={closeModal}
            footer={
              <SpaceBetween direction='horizontal' size='s'>
                <Button
                  disabled={parcelPlaybackSearchState.isPrevButtonDisabled.value || parcelPlaybackSearchState.isModalLoading.value}
                  onClick={() => {
                    updateModal(
                      parcelPlaybackSearchState.modalCameraId.value,
                      true,
                      false,
                      parcelPlaybackSearchState.modalVideoStartTime.value,
                      parcelPlaybackSearchState.modalVideoEndTime.value);
                  }}
                  variant='primary'
                >
                  {bundle.getMessage('previous-30-seconds')}
                </Button>
                <Button
                  disabled={parcelPlaybackSearchState.isNextButtonDisabled.value || parcelPlaybackSearchState.isModalLoading.value}
                  onClick={() => {
                    updateModal(
                      parcelPlaybackSearchState.modalCameraId.value,
                      false,
                      true,
                      parcelPlaybackSearchState.modalVideoStartTime.value,
                      parcelPlaybackSearchState.modalVideoEndTime.value);
                  }}
                  variant='primary'
                >
                  {bundle.getMessage('next-30-seconds')}
                </Button>
              </SpaceBetween>
            }
            header={
              <Header variant='h3'>{parcelPlaybackSearchState.modalCameraName.value}</Header>
            }
          >
            {parcelPlaybackSearchState.isModalLoading.value ? (
              <div>
                <Flashbar
                  items={[
                    {
                      type: 'success',
                      loading: true,
                      content: `${bundle.getMessage('loading-video-message')}...`,
                      id: 'ParcelPlayBackModelLoadingVideo',
                    },
                  ]}
                />
              </div>
            ) : (
              <>
                {parcelPlaybackSearchState.modalUrl.value === ''
                &&
                <div>
                  Error loading video.  For more information please see the <a href='https://w.amazon.com/bin/view/AccSys/ParcelPlayback/' target='_blank'>User Guide</a>.
                </div>}
                {parcelPlaybackSearchState.modalUrl.value !== ''
                &&
                <ReactPlayer url={parcelPlaybackSearchState.modalUrl.value} controls width='100%' />}
              </>
            )}
          </Modal>}
        </Form>
      </form>
    </Container>);
}
