import { CardItemsInterface } from 'src/components/ParcelSearch/ParcelSearchTablePanel';
import { devtools } from '@hookstate/devtools'
import { hookstate } from '@hookstate/core';
import { addDays } from 'date-fns';
import { StepOptions } from 'src/constants';

export interface ParcelPlaybackBaseStateInterface {
  darkMode: boolean;
  isAdmin: boolean | undefined;
  selectedRegion: string | undefined;
  selectedSite:
    | {
        siteCode: string;
        vms: string;
      }
    | undefined;
  stage: string | undefined;
  username: string | undefined;
}

export const ParcelPlaybackBaseState = hookstate<ParcelPlaybackBaseStateInterface>({
  darkMode: false,
  isAdmin: undefined,
  selectedRegion: undefined,
  selectedSite: undefined,
  stage: 'gamma',
  username: undefined,
}, devtools({ key: 'parcel-playback-base'}));

export interface ParcelPlaybackSearchStateInterface {
  activeTabId: string;
  cameraLoadProgress: Record<string, {loaded: number, total: number}>;
  cardItems: CardItemsInterface[];
  inductDisableTab: boolean;
  inductLabel: string | undefined;
  invalidSearchDate: boolean;
  isModalLoading: boolean;
  isNextButtonDisabled: boolean;
  isPrevButtonDisabled: boolean;
  loadingTrackingData: boolean;
  modalCameraId: string;
  modalCameraName: string;
  modalUrl: string;
  modalVideoStartTime: string;
  modalVideoEndTime: string;
  modalVideoVisible: boolean;
  noParcelDataFound: boolean;
  pickDisableTab: boolean;
  pickLabel: string | undefined;
  postRoll: string;
  postRollOptions: string | undefined;
  postUrl: string;
  preRoll: string;
  preRollOptions: string | undefined;
  preUrl: string;
  searchStartDate: string | undefined;
  selectedSteps: {label: string, value: string}[];
  stageDisableTab: boolean;
  stagingLabel: string;
  stepLoadingStatus: Record<string, boolean>;
  stowDisableTab: boolean;
  stowLabel: string | undefined;
  trackingId: string | undefined;
}

export const ParcelPlaybackSearchState = hookstate<ParcelPlaybackSearchStateInterface>({
  activeTabId: 'Induct',
  cameraLoadProgress: {},
  cardItems: [],
  inductDisableTab: true,
  inductLabel: 'Induct - No Timestamp',
  invalidSearchDate: false,
  isModalLoading: false,
  isNextButtonDisabled: false,
  isPrevButtonDisabled: false,
  loadingTrackingData: false,
  modalCameraId: '',
  modalCameraName: '',
  modalUrl: '',
  modalVideoStartTime: '',
  modalVideoEndTime: '',
  modalVideoVisible: false,
  noParcelDataFound: false,
  pickDisableTab: true,
  pickLabel: 'Pick - No Timestamp',
  postRoll: '15 seconds',
  postRollOptions: undefined,
  postUrl: '',
  preRoll: '15 seconds',
  preRollOptions: undefined,
  preUrl: '',
  searchStartDate: addDays(new Date(), -7).toISOString().split('T')[0],
  selectedSteps: StepOptions.map(s => (
    {
      label: s.label,
      value: s.value,
    })),
  stageDisableTab: true,
  stagingLabel: 'Staging - No Timestamp',
  stepLoadingStatus: {},
  stowDisableTab: true,
  stowLabel: 'Stow - No Timestamp',
  trackingId: undefined,
}, devtools({ key: 'parcel-playback-search'}));