import {
  ParcelPlaybackBaseStateInterface,
  ParcelPlaybackSearchState,
 } from 'src/stores/app';
import SideNavigation, {
  SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import {
  State,
  useHookstate,
} from '@hookstate/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Spinner } from '@amzn/awsui-components-react';
// import { isAdmin } from 'src/utils/UserPermissionUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { Step } from 'src/API';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';

interface ParcelPlaybackConfigurationNavigationLocalProps extends RouteComponentProps {
  parcelPlaybackState?: State<ParcelPlaybackBaseStateInterface>;
}

const ParcelPlaybackConfigNavigationLocal = (props: ParcelPlaybackConfigurationNavigationLocalProps) => {

  const [bundle, isBundleLoading] = useBundle('components.Navigation');

  const parcelPlaybackSearchState = useHookstate(ParcelPlaybackSearchState);

  const cameraLinksQuery = useQuery({ queryKey: [QueryKeys.cameraLinks], enabled: false });
  const camerasQuery = useQuery({ queryKey: [QueryKeys.cameras], enabled: false });

  // If the provided link is empty, do not redirect pages
  const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    event.preventDefault();
    if (event.detail.href) {
      props.history.push(event.detail.href.substring(1));
    }
  }

  // TODO: Reinstate post Pilot, assumption is that Sesame will be used
  // const checkUserIsAdmin() => {
    // const username = props.parcelPlaybackState?.username.value;
    // if (props.parcelPlaybackState?.isAdmin === undefined) {
      // isAdmin(username!).then((result) => {
        // props.parcelPlaybackState?.isAdmin.set(result);
      // });
    // }
  // }

  // checkUserIsAdmin();  // TODO: Reinstate post Pilot, assumption is that Sesame will be used

  if (props.location.pathname === '/') props.location.pathname = '/ParcelSearch';

  const home = {
    text: 'Parcel Playback',
    href: '#/',
  };


  if (isBundleLoading) return <Spinner />;

  let sideNavigationItems: SideNavigationProps.Item[] = [
    {
      href: '#/ParcelSearch',
      info:
        parcelPlaybackSearchState.loadingTrackingData.value
        || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Induct]
        || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Stow]
        || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Pick]
        || parcelPlaybackSearchState.stepLoadingStatus.value[Step.Staging]
          ? <Spinner/>
          : <></>,
      text: bundle.getMessage('parcel-search'),
      type: 'link',
    },
    {
      href: '#/CameraLinks',
      info:
        cameraLinksQuery.fetchStatus === 'fetching'
        || cameraLinksQuery.isFetching
        || cameraLinksQuery.isLoading
        || camerasQuery.fetchStatus === 'fetching'
        || camerasQuery.isFetching
        || camerasQuery.isLoading
          ? <Spinner/>
          : <></>,
      text: bundle.getMessage('camera-links'),
      type: 'link',
    },
  ];

  return (
    <SideNavigation
      activeHref={`#${props.location.pathname}`}
      header={home}
      items={sideNavigationItems}
      onFollow={onFollowHandler.bind(this)}
    />
  );
}

export const ParcelPlaybackConfigNavigation = withRouter(ParcelPlaybackConfigNavigationLocal);
