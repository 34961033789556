import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  useEffect,
  useState,
} from 'react';
import App from './App';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { ParcelPlaybackBaseStateInterface } from 'src/stores/app';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { State } from '@hookstate/core';

const queryClient = new QueryClient();

export default function AuthorizeUser(props: {
  parcelPlaybackState: State<ParcelPlaybackBaseStateInterface>;
}) {
  const [hasPermissionValue, setHasPermissionValue] = useState<boolean>();

  let userHasPermission = false;

  useEffect(() => {
    const username = props.parcelPlaybackState.username.value;
    if (username) {
      const getHasPermission = async () => {
        // all authenticated users have permission
        userHasPermission = true;
        setHasPermissionValue(userHasPermission);
      };
      getHasPermission();
    }
  }, [props.parcelPlaybackState.username.value]);

  let componentToRender;

  if (hasPermissionValue) {
    componentToRender = (
      <QueryClientProvider client={queryClient}>
        <App parcelPlaybackState={props.parcelPlaybackState} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  } else if (hasPermissionValue === undefined) {
    componentToRender = (
      <Box margin={{ top: 's', left: 's' }} color='inherit' display='block' fontSize='heading-l'>
        Checking permissions for <strong>{props.parcelPlaybackState.username.value}</strong>... If
        this page does not refresh automatically after a brief pause please contact
        <Link external href='https://w.amazon.com/index.php/SIDE#HContactus'>
          {' '}
          SIDE Support
        </Link>
      </Box>
    );
  } else {
    componentToRender = (
      <Box margin={{ top: 's', left: 's' }} color='inherit' display='block' fontSize='heading-l'>
        <strong>{props.parcelPlaybackState.username.value}</strong> does not have permission to
        access ParcelPlayback Configuration, if you believe this is incorrect please contact
        <Link external href='https://w.amazon.com/index.php/SIDE#HContactus'>
          {' '}
          SIDE Support
        </Link>
      </Box>
    );
  }

  return componentToRender;
}
