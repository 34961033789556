import Alert from '@amzn/awsui-components-react/polaris/alert';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import { URLS } from 'src/constants';
import { useState } from 'react';

export default function FallbackError({ error }: { error: Error }) {
  const [visible, setVisible] = useState(true);
  return visible ? (
    <Alert
      statusIconAriaLabel='Error'
      type='error'
      header='Error'
      dismissible={false}
      onDismiss={() => {
        setVisible(false);
      }}
    >
      <div role='alert'>
        <h2>Something went wrong.</h2>
        <pre>{error.message}</pre>
        <ExpandableSection headerText='Error Details'>{error.stack}</ExpandableSection>
        <pre>
          Please report an{' '}
          <a href={URLS.RiverGuide} target='_blank'>
            issue
          </a>{' '}
          and <a href='./'>refresh</a> the page.
        </pre>
      </div>
    </Alert>
  ) : (
    <></>
  );
}
