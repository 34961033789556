import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import {
  Camera,
  PilotSite,
  VMS,
} from 'src/API';
import {
  debug,
  getCameraLinks,
  getCameras,
  getUserSites,
} from 'src/utils/utils';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import CameraLinks from './CameraLinks';
import { CameraLinkWithCameraNameAndDesc } from './CameraLinks/table-config';
import { ParcelSearch } from './ParcelSearch';
import { ParcelPlaybackBaseStateInterface } from 'src/stores/app';
import { QueryKeys } from 'src/constants';
import SelectSite from './common/SelectSite';
import { State } from '@hookstate/core';
import { TopNav } from './TopNav';
import { useEffect, useState } from 'react';

import '@amzn/awsui-global-styles/polaris.css';

export let debugLogging: boolean = false;

export default function App(props: { parcelPlaybackState: State<ParcelPlaybackBaseStateInterface> }) {
  debug(`App()`);

  const parcelPlaybackState = props.parcelPlaybackState;

  const queryClient = useQueryClient();

  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [showSelectSite, setShowSelectSite] = useState<boolean>(false);
  const [userSites, setUserSites] = useState<PilotSite[]>([]);

  debugLogging = true;

  const userSitesQuery = useQuery({
    queryFn: async (): Promise<PilotSite[]> =>
      await getUserSites(
        props.parcelPlaybackState.value.username!,
        props.parcelPlaybackState.value.stage!,
      ),
    queryKey: [QueryKeys.userSites],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
    staleTime: Infinity,
  });

  const camerasQuery = useQuery({
    queryFn: async (): Promise<Camera[]> => {
      const siteCode = props.parcelPlaybackState.value.selectedSite?.siteCode;
      const siteVms = props.parcelPlaybackState.value.selectedSite?.vms as VMS;
      if (!siteCode) return [];
      const cameras = await getCameras(siteCode, siteVms);
      return cameras;
    },
    queryKey: [QueryKeys.cameras],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 3,
    staleTime: Infinity,
  });

  const cameraLinksQuery = useQuery({
    queryFn: async (): Promise<CameraLinkWithCameraNameAndDesc[]> => {
      const siteCode = props.parcelPlaybackState.value.selectedSite?.siteCode;
      if (!siteCode || !camerasQuery?.data || camerasQuery.data.length === 0) return [];
      const cameraLinks = (await getCameraLinks(siteCode)).map(cl => {
        return(
          {
            ...cl,
            camerasWithNameAndDesc: cl.cameras?.map(c => {
              const camera = (camerasQuery.data as Camera[])?.find(cam => cam.systemIdentifier === c?.systemIdentifier);
              return({
                ...camera,
                name: camera?.name,
                description: camera?.description,
              });
            }),
          } as CameraLinkWithCameraNameAndDesc
        );
      });
      return cameraLinks;
    },
    queryKey: [QueryKeys.cameraLinks],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 3,
    staleTime: Infinity,
  });

  const refreshUserSites = async (): Promise<void> => {
    userSitesQuery.refetch();
  };

  const siteSelected = async () => {
    queryClient.setQueryData([QueryKeys.cameras], []);
    camerasQuery.refetch();
    cameraLinksQuery.refetch();
    setShowSelectSite(false);
  };

  useEffect(() => {
    debug(`App() useEffect[darkMode]`);
    if (darkMode) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
  }, [darkMode]);

  useEffect(() => {
    setUserSites(userSitesQuery.data ?? []);
  }, [userSitesQuery.data]);

  useEffect(() => {
    cameraLinksQuery.refetch();
  }, [camerasQuery.data]);

  useEffect(() => {
    if (!parcelPlaybackState.value.selectedSite) setShowSelectSite(true);
    const init = async () => {
      await refreshUserSites();
      let lastSelectedSite: { siteCode: string, vms: VMS} | null = null;
      const lastSelectedSiteFromLocalStorage = localStorage.getItem('lastSelectedSite');
      debug(`App() useEffect()[] lastSelectedSiteFromLocalStorage is ${lastSelectedSiteFromLocalStorage}`);
      if (lastSelectedSiteFromLocalStorage) {
        try {
          lastSelectedSite = JSON.parse(lastSelectedSiteFromLocalStorage ?? '{}');
          debug(`App() useEffect()[] lastSelectedSite is ${JSON.stringify(lastSelectedSite)}`);
          if (lastSelectedSite?.siteCode && lastSelectedSite?.vms) {
            props.parcelPlaybackState.selectedSite.set({
              vms: lastSelectedSite.vms,
              siteCode: lastSelectedSite.siteCode,
            });
            setShowSelectSite(false);
            await camerasQuery.refetch();
            cameraLinksQuery.refetch();
          }
        } catch(error) {
          console.error('App() useEffect()[] error:', error);
        }
      }
      const lastDarkMode = localStorage.getItem('darkMode');
      if (lastDarkMode) {
        setDarkMode(lastDarkMode === 'true');
      }
    }
    init();
  }, []);

  return (
    <>
      <div
        className='awsui'
        id='topNavigation'
        style={{ position: 'sticky', top: 0, zIndex: 1002 }}
      >
        <TopNav
          setDarkModeCallback={setDarkMode}
          setShowSelectSite={(v: boolean) => {
            setShowSelectSite(v);
          }}
          siteCode={parcelPlaybackState.value.selectedSite?.siteCode || ''}
          username={parcelPlaybackState.value.username || ''}
        />
      </div>
      {showSelectSite && (
        <SelectSite
          parcelPlaybackState={parcelPlaybackState}
          refreshUserSitesCallback={refreshUserSites}
          siteSelectedCallback={siteSelected}
          userSites={userSites}
          userSitesLoading={userSitesQuery.isFetching}
        />
      )}
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path='/'
            render={() =>
              <ParcelSearch
                parcelPlaybackState={parcelPlaybackState}
              />}
          />
          <Route
            path='/CameraLinks'
            render={() =>
              <CameraLinks
                parcelPlaybackState={parcelPlaybackState}
              />}
          />
          <Route
            path='/ParcelSearch'
            render={() =>
              <ParcelSearch
                parcelPlaybackState={parcelPlaybackState}
              />}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}
