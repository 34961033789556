/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCameraLink = /* GraphQL */ `mutation CreateCameraLink($input: CreateCameraLinkInput!) {
  createCameraLink(input: $input) {
    cameras {
      systemIdentifier
      __typename
    }
    created
    createdBy
    siteCode
    stepName
    updated
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCameraLinkMutationVariables,
  APITypes.CreateCameraLinkMutation
>;
export const createUserAction = /* GraphQL */ `mutation CreateUserAction($input: UserActionInput!) {
  createUserAction(input: $input) {
    actionId
    actionName
    parameters
    timestamp
    ttl
    username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserActionMutationVariables,
  APITypes.CreateUserActionMutation
>;
export const deleteCameraLink = /* GraphQL */ `mutation DeleteCameraLink($input: DeleteCameraLinkInput!) {
  deleteCameraLink(input: $input) {
    cameras {
      systemIdentifier
      __typename
    }
    created
    createdBy
    siteCode
    stepName
    updated
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCameraLinkMutationVariables,
  APITypes.DeleteCameraLinkMutation
>;
export const updateCameraLink = /* GraphQL */ `mutation UpdateCameraLink($input: UpdateCameraLinkInput!) {
  updateCameraLink(input: $input) {
    cameras {
      systemIdentifier
      __typename
    }
    created
    createdBy
    siteCode
    stepName
    updated
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCameraLinkMutationVariables,
  APITypes.UpdateCameraLinkMutation
>;
