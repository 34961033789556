import { useState, useEffect } from 'react';

// Returns the saved page size, else the default value for that page
const getPageSize = (page: string, defaultPageSize: number): number => {
  const savedPageSize = sessionStorage.getItem(page);
  return savedPageSize ? parseInt(savedPageSize) : defaultPageSize;
};

// Saves the Page size based on the Page name, which acts as the Key while storing the PageSize
const savePageSize = (page: string, pageSize: number): void => {
  sessionStorage.setItem(page, pageSize.toString());
};

/* 
Custom function to manage the PageSize based on User's preference, else set it to the default Page size 
Currently default values are: 
 - Camera Links Page = 15
 - Parcel Search Page = 25

@params - page :- Name of the Page 
        - defaultPageSize :- Default page size for that particular page
*/
export const fetchPageSize = (
  page: string,
  defaultPageSize: number,
): [number, (newPageSize: number) => void] => {
  const [pageSize, setPageSize] = useState(() => getPageSize(page, defaultPageSize));

  useEffect(() => {
    const savedPageSize = getPageSize(page, defaultPageSize);
    if (savedPageSize! == pageSize) {
      setPageSize(savedPageSize);
    }
  }, [page, pageSize, defaultPageSize]);

  const handlePageSizeChange = (newpageSize: number) => {
    setPageSize(newpageSize);
    savePageSize(page, newpageSize);
  };

  return [pageSize, handlePageSizeChange];
};
