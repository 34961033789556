import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { ParcelPlaybackBaseState } from 'src/stores/app';
import { debug } from './utils';
import noImageFound from 'src/assets/images/NoImageFound.jpg';
import { useState } from 'react';

export const fetchCameraImage = () => {
  const [cameraImage, setCameraImage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [blobUrl, setBlobUrl] = useState<string | undefined>(undefined);
  const [timeStamp, setTimeStamp] = useState<string | undefined>(undefined);

  // Converting the base64 image string to blob, to display the full size image in a separate window on user request
  // If we try to display the base64 string as is, due to browser limitations, not able to display certain images
  const convertBase64ToBlobUrl = (base64: string) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  const fetchImage = async (cameraId: string) => {
    debug(`fetchImage() cameraId is ${cameraId}`);

    setLoading(true);

    // Retrieve cached values for the specific cameraId from sessionStorage
    const cachedData = sessionStorage.getItem(`cameraData_${cameraId}`);
    if (cachedData) {
      const { image, blobUrl, timeStamp } = JSON.parse(cachedData);
      setCameraImage(image);
      setBlobUrl(blobUrl);
      setTimeStamp(timeStamp);
      setLoading(false);
      return;
    }

    AWS.config.credentials = await Auth.currentCredentials();
    AWS.config.region = 'us-east-1';

    try {
      const lambda = new AWS.Lambda();
      const result = await lambda
        .invoke({
          FunctionName: 'ImageCaptureLambda',
          Payload: JSON.stringify({
            cameraId: cameraId.toUpperCase(),
            siteCode: ParcelPlaybackBaseState.value.selectedSite?.siteCode,
          }),
        })
        .promise();

      const api_response = JSON.parse(result.Payload as string);

      if (api_response.statusCode === 200) {
        const image_url = `data:image/jpeg;base64,${api_response.body?.replace(/"/g, '').trim()}`;
        const timeStamp = new Date(api_response?.imageCapturedUTCTime);
        const blobUrl = convertBase64ToBlobUrl(image_url);

        // Cache values in sessionStorage
        try {
          const dataToCache = {
            image: image_url,
            blobUrl: blobUrl,
            timeStamp: timeStamp.toString(),
          };
          sessionStorage.setItem(`cameraData_${cameraId}`, JSON.stringify(dataToCache));
        } catch (e) {
          if (e instanceof DOMException && e.QUOTA_EXCEEDED_ERR === 22) {
            console.warn('Storage quota exceeded, skipping caching');
          } else {
            console.error('Error caching data: ', e);
          }
        }

        setCameraImage(image_url);
        setBlobUrl(blobUrl);
        setTimeStamp(timeStamp.toString());
      } else {
        // Setting the Image URL to :  No Image Availale
        setCameraImage(noImageFound);
        // Setting the blob as empty, as we are not providing full size image option in-case image is not loaded/available
        setBlobUrl('');
      }
    } catch (error) {
      console.error('fetchCamerImage Error:', error);
      setCameraImage(noImageFound);
    } finally {
      setLoading(false);
    }
  };

  return { timeStamp, blobUrl, cameraImage, loading, fetchImage };
};
